/*
  Standard format for a route element:
  {
    path: "/",
    component: SomeComponent,
    isPrivate: true
  }
*/

import React from 'react'
import Loader from '../components/loader/loader'
const Auth = React.lazy(() => import('../pages/auth/Auth'))
const Home = React.lazy(() => import('../pages/home/Home'))
const Tech = React.lazy(() => import('../pages/domain/tech'))
const Management = React.lazy(() => import('../pages/domain/management'))
const Design = React.lazy(() => import('../pages/domain/design'))
const Domain = React.lazy(() => import('../pages/domain/domains'))
const Test = React.lazy(() => import('../pages/test/test'))
const Dashboard = React.lazy(() => import('../pages/dashboard/dashboard'))

export const routes = [
  {
    path: '/',
    component: Home
  },
  {
    path: '/auth',
    component: Auth
  },
  {
    path: '/domain/tech',
    component: Tech,
    // isPrivate: true,
    deps: ['/domain']
  },
  {
    path: '/domain/design',
    component: Design,
    // isPrivate: true,
    deps: ['/domain']
  },
  {
    path: '/domain/management',
    component: Management,
    // isPrivate: true,
    deps: ['/domain']
  },
  {
    path: '/domain',
    component: Domain,
    // isPrivate: true,
    deps: ['/auth']
  },
  {
    path: '/dashboard/test',
    component: Test,
    // isPrivate: true,
    deps: ['/dashboard']
  },
  {
    path: '/dashboard',
    component: Dashboard,
    // isPrivate: true,
    deps: ['/']
  }
]

export const fallbackComponent = () => {
  window.location.assign('/')

  return <Loader />
}
