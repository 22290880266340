import { url } from './base'
import axios from 'axios'

export async function getDashboard (token) {
  const apiUrl = url('/core/dashboard')
  const resp = await axios.get(apiUrl, { headers: { authorization: `Bearer ${token}` } })
  return resp.data
}

export const getAvailableTests = async (token) => {
  const apiUrl = url('/tests/available/')
  const resp = await axios.get(apiUrl, { headers: { authorization: `Bearer ${token}` } })
  return resp.data
}

export const getFinishedTests = async (token) => {
  const apiUrl = url('/tests/finished/')
  const resp = await axios.get(apiUrl, { headers: { authorization: `Bearer ${token}` } })
  return resp.data
}
