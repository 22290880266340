import React from 'react'
import { BrowserRouter, Route, Switch } from 'react-router-dom'
import { routes, fallbackComponent } from './config'
import { AuthProvider, SharedContextProvider, SharedContext } from './context'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

function Routes () {
  const { hasAllDeps } = React.useContext(SharedContext)
  return (
    <Switch>
      {routes.map((route) => (
        <Route
          exact
          key={route.path}
          path={route.path}
          component={
            !route.isPrivate
              ? route.component
              : hasAllDeps(route.deps)
                ? route.component
                : fallbackComponent
          }
        />
      ))}
    </Switch>
  )
}

function App () {
  return (
    <AuthProvider>
      <SharedContextProvider>
        <BrowserRouter>
          <ToastContainer
            theme='colored'
            toastClassName='font-gelionRegular text-sm'
            className='font-gelionRegular'
            position='top-right'
            autoClose={3000}
          />
          <Routes />
        </BrowserRouter>
      </SharedContextProvider>
    </AuthProvider>
  )
}

export default App
