
import { accountSvc } from '../../services'

export const setUserOnAuth = (firebaseUser, token, setAuth) => {
  const currentUser = {
    token,
    name: firebaseUser.displayName,
    photoUrl: firebaseUser.photoURL
  }
  // console.log(currentUser)
  window.localStorage.setItem('currentUser', JSON.stringify(currentUser))
  setAuth(currentUser)
}

export const oauth = async (firebaseUser, setAuth) => {
  try {
    const oauthResponse = await accountSvc.oauth(await firebaseUser.getIdToken())
    //  this has got to change
    const token = oauthResponse.token
    setUserOnAuth(firebaseUser, token, setAuth)
    return token
  } catch (e) {
    if (e.response.status === 404) return 'register'
    throw e
  }
}

export const logout = async (setAuth) => {
  window.localStorage.removeItem('currentUser')
  setAuth({
    token: null,
    name: null,
    photoUrl: null
  })
}
