import { url } from './base'
import axios from 'axios'

export const questionRound0 = async (idToken, testId) => {
  const apiUrl = url(`/tests/round0/questions/?test_id=${testId}`)
  const resp = await axios.get(apiUrl, { headers: { authorization: `Bearer ${idToken}` } })
  return resp.data
}

export const answerRound0 = async (idToken, answers, testId) => {
  const apiUrl = url('/tests/round0/answers/')
  const resp = await axios.post(apiUrl, { test_id: testId, answers: answers }, { headers: { authorization: `Bearer ${idToken}` } })
  return resp.data
}

export const enterRound1 = async (idToken, testId) => {
  const apiUrl = url(`/tests/round1/enter_session/?test_id=${testId}`)
  const resp = await axios.get(apiUrl, { headers: { authorization: `Bearer ${idToken}` } })
  return resp.data
}

export const answerRound1 = async (idToken, testId, answer, questionId) => {
  const apiUrl = url('/tests/round1/answer/')
  const resp = await axios.post(apiUrl, { test_id: testId, answer: { question: questionId, answer: answer.answer, option: answer.option } }, { headers: { authorization: `Bearer ${idToken}` } })
  return resp.data
}

// OLD FUNCTIONS
export const registerRounds = async (idToken, subDomainIds) => {
  const apiUrl = url('/core/round/register/')
  const resp = await axios.post(apiUrl, { subdomain_id: subDomainIds }, { headers: { authorization: `Bearer ${idToken}` } })
  return resp.data
}

export const startRound = async (idToken, testId) => {
  const apiUrl = url('/core/round/questions/start/')
  try {
    const resp = await axios.post(apiUrl, { schedule_test_id: testId }, { headers: { authorization: `Bearer ${idToken}` } })
    return resp.data
  } catch (e) {
    console.log(e)
  }
}

export const getRound1Question = async (idToken, testId) => {
  const apiUrl = url(`/core/round/1/question/next/?test_id=${testId}`)
  const resp = await axios.get(apiUrl, { headers: { authorization: `Bearer ${idToken}` } })
  return resp.data
}

// export const getRound0Questions = async (idToken, testId) => {
//   const apiUrl = url(`/core/round/questions/get/?test_id=${testId}`)
//   const resp = await axios.get(apiUrl, { headers: { authorization: `Bearer ${idToken}` } })
//   return resp.data
// }

export const answerRound1Question = async (idToken, testId, answer) => {
  const apiUrl = url('/core/round/1/submit/')
  const resp = await axios.post(apiUrl, { id: testId, answer }, { headers: { authorization: `Bearer ${idToken}` } })
  return resp.data
}

// export const answerRound0Question = async (idToken, questionId, answer) => {
//   const apiUrl = url('/core/round/0/submit/')
//   const resp = await axios.post(apiUrl, { id: questionId, answer }, { headers: { authorization: `Bearer ${idToken}` } })
//   return resp.data
// }

// export const submitRound0Test = async (idToken, testId) => {
//   const apiUrl = url('/core/round/0/submit_exam/')
//   const resp = await axios.patch(apiUrl, { test_id: testId }, { headers: { authorization: `Bearer ${idToken}` } })
//   return resp.data
// }
