import React from 'react'
import PropTypes from 'prop-types'

export const AuthContext = React.createContext({})

export function AuthProvider (props) {
  const authObj = JSON.parse(window.localStorage.getItem('currentUser'))
  const [auth, setAuth] = React.useState(authObj ?? { token: null, name: '', photoUrl: '' })
  return (
    <AuthContext.Provider value={{ auth, setAuth }}>
      {props.children}
    </AuthContext.Provider>
  )
}

AuthProvider.propTypes = {
  children: PropTypes.any
}
