import axios from 'axios'
import { url } from './base'

export const oauth = async (idToken) => {
  const oauthUrl = url('/account/login/')
  const oauthResp = await axios.post(oauthUrl, { token: idToken })
  return oauthResp.data
  // if 404 redirect to register
}

export const fillProfile = async (params) => {
  const apiUrl = url('/account/profile/create/')
  // const resp = await axios.post(apiUrl, params, { headers: { authorization: `Bearer ${idToken}` } })
  const resp = await axios.post(apiUrl, params)
  return resp.data
}

export const getProfile = async (idToken) => {
  const apiUrl = url('/account/profile/')
  const resp = await axios.get(apiUrl, {
    headers: { authorization: `Bearer ${idToken}` }
  })
  return resp.data
}

export const updateProfile = async (idToken, params) => {
  const apiUrl = url('/account/profile/update')
  const resp = await axios.patch(apiUrl, params, {
    headers: { authorization: `Bearer ${idToken}` }
  })
  return resp.data
}
