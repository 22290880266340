import React from 'react'
import PropTypes from 'proptypes'
import { accountSvc, roundSvc } from '../../services'
import { AuthContext } from '../auth'

export const SharedContext = React.createContext({})

export function SharedContextProvider (props) {
  const { auth } = React.useContext(AuthContext)
  const domainMap = {
    general: 'General',
    backend: 'Backend',
    frontend: 'Frontend',
    appdev: 'App Development',
    ml: 'Machine Learning',
    python: 'Python',
    cybersec: 'Cyber Security',
    graphic: 'Graphic Design',
    ui: 'UI/UX',
    video: 'Video and Motion Graphics',
    sponsorship: 'Sponsorship',
    content: 'Content Writing',
    events: 'Events'
  }
  const [visited, setVisited] = React.useState({})

  // todo: Add UUID values
  const subdomainUuidMap = {
    general: ['fc1e8dbc-75d8-4445-ab59-5c28ef39ac88'],
    backend: ['fc1e8dbc-75d8-4445-ab59-5c28ef39ac88'],
    frontend: ['fc1e8dbc-75d8-4445-ab59-5c28ef39ac88'],
    appdev: ['fc1e8dbc-75d8-4445-ab59-5c28ef39ac88'],
    ml: ['fc1e8dbc-75d8-4445-ab59-5c28ef39ac88'],
    python: ['fc1e8dbc-75d8-4445-ab59-5c28ef39ac88'],
    cybersec: ['fc1e8dbc-75d8-4445-ab59-5c28ef39ac88'],
    graphic: ['60690e85-5bc4-4811-abef-4b86409850aa'],
    ui: ['60690e85-5bc4-4811-abef-4b86409850aa'],
    video: ['60690e85-5bc4-4811-abef-4b86409850aa'],
    sponsorship: ['18175a82-c8d7-4e75-a76b-2e3d4d7f478f'],
    content: ['18175a82-c8d7-4e75-a76b-2e3d4d7f478f'],
    events: ['18175a82-c8d7-4e75-a76b-2e3d4d7f478f']
  }

  const [shared, setSharedState] = React.useState({
    profile: {
      registrationNumber: '',
      bio: '',
      branch: '',
      year: '',
      domains: '',
      phoneNumber: '',
      disqualified: false
    },
    domains: {
      tech: {
        general: false,
        backend: false,
        frontend: false,
        appdev: false,
        ml: false,
        python: false,
        cybersec: false
      },
      design: {
        graphic: false,
        ui: false,
        video: false
      },
      management: {
        sponsorship: false,
        content: false,
        events: false
      }
    },
    selectedDomains: {
      '/domain/tech': false,
      '/domain/management': false,
      '/domain/design': false
    }
  })

  const setShared = (params) => {
    setSharedState({ ...shared, ...params })
  }

  const setProfile = (params) => {
    setShared({ profile: { ...shared.profile, ...params } })
  }

  const setDomains = (params) => {
    setShared({ domains: { ...shared.domains, ...params } })
  }

  const setTechDomain = (params) => {
    setDomains({ tech: { ...shared.domains.tech, ...params } })
  }

  const setDesignDomain = (params) => {
    setDomains({ design: { ...shared.domains.design, ...params } })
  }

  const setManagementDomain = (params) => {
    setDomains({ management: { ...shared.domains.management, ...params } })
  }

  const toggleDomain = (domain) => {
    const selectedDomains = shared.selectedDomains
    selectedDomains[domain] = !selectedDomains[domain]
    setShared({ selectedDomains })
  }

  const getAllSubDomains = () => {
    const domainList = []
    for (const key of Object.keys(domainMap)) {
      if (
        shared.domains.tech[key] ||
        shared.domains.design[key] ||
        shared.domains.management[key]
      ) {
        domainList.push(domainMap[key])
      }
    }

    return domainList
  }

  const isAnyDomainToggled =
    shared.selectedDomains['/domain/tech'] ||
    shared.selectedDomains['/domain/management'] ||
    shared.selectedDomains['/domain/design']

  const getDomains = () => {
    const chosenDomains = []
    for (const key of Object.keys(domainMap)) {
      if (shared.domains.tech[key]) chosenDomains.push('tech')
      if (shared.domains.management[key]) chosenDomains.push('management')
      if (shared.domains.design[key]) chosenDomains.push('design')
    }
    // returning without duplicates
    return chosenDomains.filter((v, i, a) => a.indexOf(v) === i)
  }

  const postProfile = async (idToken) => {
    const profile = shared.profile
    const postResp = await accountSvc.fillProfile({
      id_token: idToken,
      reg_no: profile.registrationNumber,
      bio: profile.bio,
      domain: getDomains(),
      sub_domain: getAllSubDomains(),
      phone_number: profile.phoneNumber
    })
    return postResp.data.access_token
  }

  const registerForRounds = async () => {
    const subDomaindc = {}
    const subDomainIds = []

    for (const [key, value] of Object.entries({
      ...shared.domains.tech,
      ...shared.domains.design,
      ...shared.domains.management
    })) {
      if (value) {
        for (const id of subdomainUuidMap[key]) {
          subDomaindc[id] = true
        }
      }
    }

    for (const key of Object.keys(subDomaindc)) {
      subDomainIds.push(key)
    }

    await roundSvc.registerRounds(auth.token, subDomainIds)
  }

  // todo: add domain ids
  const domainIdToColorMap = {
    '48e9eac9-956b-46e1-b997-058ea09ab258': 'tech',
    '01995fb8-8037-472d-baf0-e914ad7c9ebe': 'management',
    'e4d348fd-e4a8-4ed4-9773-fc720ce35ba0': 'design'
  }

  const getDashboardRoundDetails = () => {
    return ['Round 1 active']
  }

  const hasAllDeps = (deps) => {
    for (const dep of deps) {
      if (!visited[dep]) {
        return false
      }
    }

    return true
  }

  return (
    <SharedContext.Provider
      value={{
        shared,
        setShared,
        setDomains,
        setTechDomain,
        setDesignDomain,
        setManagementDomain,
        setProfile,
        getAllSubDomains,
        toggleDomain,
        subdomainUuidMap,
        isAnyDomainToggled,
        postProfile,
        registerForRounds,
        domainIdToColorMap,
        getDashboardRoundDetails,
        visited,
        setVisited,
        hasAllDeps
      }}
    >
      {props.children}
    </SharedContext.Provider>
  )
}

SharedContextProvider.propTypes = {
  children: PropTypes.any
}
